import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { stepState } from "../../../recoil/report/stepState";
import * as XLSX from "xlsx";
import { reportDataState } from "../../../recoil/report/reportDataState";
import moment from "moment";
import { usePatient } from "../../../context/PatientContext";

const DownloadButton = () => {
  const { patient } = usePatient();
  const currentStep = useRecoilValue(stepState);
  const reportData = useRecoilValue(reportDataState);

  const handleDownload = () => {
    const workbook = XLSX.utils.book_new();

    reportData.forEach((item, index) => {
      const sheetData = item?.data?.res.map((row) => {

        var keys = Object.keys(row);
        var obj = {};

        keys.forEach(key => {
          console.log("key : ", key, "value : ", row[key])

          // do some logic here
          switch (key) {
            case 'awake':
              obj['Awake'] = row[key];
              break;

            case 'light':
              obj['Light'] = row[key];
              break;

            case 'deep':
              obj['Deep'] = row[key];
              break;

            case 'rem':
              obj['Rem'] = row[key];
              break;

            case 'total':
              obj['Total'] = row[key];
              break;

            case 'min':
              obj['Min'] = row[key];
              break;

            case 'max':
              obj['Max'] = row[key];
              break;

            case 'avg':
              obj['Avg'] = row[key];
              break;

            case 'median':
              obj['Median'] = row[key];
              break;

            case 'diamin':
              obj['Diastolic Min'] = row[key];
              break;

            case 'diamax':
              obj['Diastolic Max'] = row[key];
              break;

            case 'diaavg':
              obj['Diastolic Avg'] = row[key];
              break;

            case 'diamedian':
              obj['Diastolic Median'] = row[key];
              break;

            case 'sysmin':
              obj['Systolic Min'] = row[key];
              break;

            case 'sysmax':
              obj['Systolic Max'] = row[key];
              break;

            case 'sysavg':
              obj['Systolic Avg'] = row[key];
              break;

            case 'sysmedian':
              obj['Systolic Median'] = row[key];
              break;

            case 'text':
              obj['Description'] = row[key];
              break;

            case 'ntDate':
              obj['Date'] = moment(row[key]).format("MMM DD, YYYY");
              break;

            case 'ntTime':
              obj['Time'] = moment(row[key], 'hh:mm:ss').format("hh:mm A");
              break;

            case 'evDate':
              obj['Date'] = moment(row[key]).format("MMM DD, YYYY");
              break;

            case 'evTime':
              obj['Time'] = moment(row[key], 'hh:mm:ss').format("hh:mm A");
              break;

            case 'typeName':
              obj['Category'] = row[key];
              break;

            case 'user':
              obj['Created By'] = row[key];
              break;

            case 'isImp':
              obj['Starred'] = row[key] ? 'Yes' : 'No';
              break;

            case 'date':
              obj['Date'] = moment(row[key]).format("MMM DD, YYYY");
              break;

            case 'id':
            case 'recipientId':
            case 'type':
            case 'userId':
            case 'profileImage':
            case 'createdDate':
            case 'vitalTypeId':
              break;

            default:
              obj[key] = row[key];
              break;
          }
        })

        obj = preferredOrder(obj, [
          "Date", "Description", "Starred", "Time", "Created By",
          "Light","Deep","Rem","Awake","Total",
          "Min","Max","Avg","Median",
          "Diastolic Min","Diastolic Max","Diastolic Avg","Diastolic Median",
          "Systolic Min","Systolic Max","Systolic Avg","Systolic Median"]);
        return obj;
      });

      const worksheet = XLSX.utils.json_to_sheet(sheetData.length ? sheetData : [{ "No data available" : "" }]);
      XLSX.utils.book_append_sheet(workbook, worksheet, item?.data?.label);
    });

    XLSX.writeFile(
      workbook,
      `${patient?.fname + patient?.lname}_${moment().format("DD-MM-YYYY")}.xlsx`
    );
  };

  const preferredOrder = (obj, order) => {
    var newObject = {};
    for (var i = 0; i < order.length; i++) {
      if (obj.hasOwnProperty(order[i])) {
        newObject[order[i]] = obj[order[i]];
      }
    }
    return newObject;
  }

  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  useEffect(() => {
    let timer;
    if (currentStep === 3) {
      timer = setTimeout(() => {
        setIsButtonEnabled(true);
      }, 4000);
    } else {
      setIsButtonEnabled(false);
    }

    return () => clearTimeout(timer);
  }, [currentStep]);
  return (
    <div>
      {/* Share Icon  */}
      {currentStep === 3 && (
        <button
          className={`bg-[#ED7117] flex items-center justify-center p-2 px-5 text-white font-medium rounded-lg h-12 uppercase text-sm xs:text-lg mt-5 border border-black gap-x-3 ${!isButtonEnabled ? "opacity-50" : ""
            }`}
          onClick={handleDownload}
          disabled={!isButtonEnabled}
        >
          {!isButtonEnabled ? "Generating..." : "EXCEL"}
          <img src="./icons/download.svg" alt="Download" className="w-5 h-5" />
        </button>
      )}
    </div>
  );
};

export default DownloadButton;
