import moment from "moment/moment";

// Warning!!! this function should be defined at top level of this file as it is getting used in following function/functions in this file
// Formats a date into YYYY-MM-DD format for consistent comparison.
// YYYY-MM-DDTHH:mm:ss => YYYY-MM-DD
// UTC => Local // Local => Local
export const getYYYYMMDDFormat = (date = new Date(), localized = true) => {
  const newDate = moment(date + (localized ? 'Z' : '')).format("YYYY-MM-DD");
  return newDate
}

// Warning!!! this function should be defined at top level of this file as it is getting used in following function/functions in this file
// Return date format is '2024-01-11'
export const getCurrentDate = () => moment().format("YYYY-MM-DD");

export const getCurrentDateTime = () => moment().format('YYYY-MM-DD HH:mm:ss')

// 2024-05-11T07:12:01.903Z into 2024-05-11
export const convertDate = (date) => moment(date, "YYYY-MM-DD").format("YYYY-MM-DD")

// unix-epoch-milli => unix-epoch-milli
// UTC => Local
export const convertToLocalDateEpoch = (utcEpoch) => {
  // we have decided not to localize things
  // const offsetInSeconds = moment().utcOffset() * 60 * 1000

  const localizedEpoch = utcEpoch  // + offsetInSeconds

  // Get the date part of the local time (year, month, day)
  const localDateOnly = moment.utc(localizedEpoch).format("YYYY-MM-DD");

  // Convert the local date back to Unix epoch time in milliseconds
  const localDateEpoch = moment.utc(localDateOnly).valueOf();

  return localDateEpoch;
}

// Return local date in format 'YYYY-MM-DD'
export const getCurrentLocalDate = () => {
  // const today = new Date();
  // const year = today.getFullYear();
  // let month = today.getMonth() + 1; // Months are zero-based
  // let day = today.getDate();

  // // Add leading zero if month or day is a single digit
  // month = month < 10 ? `0${month}` : month;
  // day = day < 10 ? `0${day}` : day;

  // const previousFormat =  `${year}-${month}-${day}`;

  const currentDate = moment().format('YYYY-MM-DD')
  return currentDate
};

// very unique format
// YYYY-MM-DD => YYYY-MM-DD HH:mm:sss
// Local => UTC
export const convertLocalDateToUTCDateTime = (localDate) => {
  return moment(localDate, "YYYY-MM-DD").utc().format("YYYY-MM-DD HH:mm:ss");
}


// YYYY-MM-DDTHH:mm:ss => YYYY-MM-DD HH:mm:ss
// Local => UTC
export const convertLocalDateTimeToUTCDateTime = (localDateTime) =>
  moment(localDateTime, "YYYY-MM-DDTHH:mm:ss").utc().format("YYYY-MM-DDTHH:mm:ss");

// YYYY-MM-DD, 15:12 => YYYY-MM-DD
// Local => UTC
export const convertLocalDateToUTC = (localDate, localTime) => {
  // Combine the date and time into a single string
  const localDateTime = `${localDate}T${localTime}`;

  // Create a moment object from the combined local date and time string
  const localMoment = moment(localDateTime, "YYYY-MM-DDTHH:mm");

  // Convert the local moment to UTC
  const utcMoment = localMoment.utc();

  // Format the UTC moment as a string
  return utcMoment.format("YYYY-MM-DD");
};

// YYYY-MM-DD, 15:12 => YYYY-MM-DD
// Local => UTC
export const convertLocalDateToUTCOffset = (localDate, localTime) => {
  // Combine local date and time into a single moment object
  const localDateTime = moment(`${localDate}T${localTime}`);

  // Get the timezone offset in minutes
  const timezoneOffsetMinutes = localDateTime.utcOffset();

  // Convert the offset from minutes to seconds
  const offsetSeconds = timezoneOffsetMinutes * 60;

  return offsetSeconds;
}

// 13:14 => 13:14
// Local => UTC
export const convertLocalDateTimeToUTC = (localDateTime) => {
  // Create a moment object from the local date and time string
  const localMoment = moment(localDateTime, "HH:mm");

  // Convert the local moment to UTC
  const utcMoment = localMoment.utc();

  // Format the UTC moment as a string
  return utcMoment.format("HH:mm");
};

// 2024-05-14T07:11:00 to YYYY-MM-DD format
export const convertToLocalDate = (dateString) => {
  // Create a new Date object from the given UTC date string
  const utcDate = new Date(dateString + "Z");

  // Get the local date components
  const day = utcDate.getDate();
  const month = utcDate.getMonth() + 1; // Months are zero-indexed, so add 1
  const year = utcDate.getFullYear();

  // Format the date components to DD-MM-YYYY format
  return `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""
    }${day}`;
};

// Warning!!! this function should be defined at top level of this file as it is getting used in following function/functions in this file
// Return ISO time in format '18:44'
export const getCurrentISOTime = () =>
  new Date().toISOString().split("T").at(1).slice(0, 5);

// Return local time in format '01:02'
export const getCurrentLocalTime = () =>
  new Date().toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

// 2024-05-14T07:11:00 to '01:02' format
export const convertToLocalTime = (timeString) =>
  new Date(timeString + "Z").toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

// Extracts chart items that have a matching date with events in the events array.
// For Line-graphs 
export const extractChartItemsWithMatchingEventDates = (events, chartData) => {
  const formattedEventDates = new Set(
    events?.map((event) => getYYYYMMDDFormat(getCorrectDateTimeString(event.evDate, event.evTime)))
  ); // Create a Set of formatted event dates for efficient lookups.

  // Filter the chartData, if it exists, to keep only items with matching dates.
  const filteredEvents = chartData?.map((chartItem) => {
    const miliToTimestamp = moment.utc(chartItem.at(0)).format("YYYY-MM-DD") // formatting the date without changing the timezone
    return formattedEventDates.has(miliToTimestamp)
      ? chartItem
      : [chartItem.at(0), null]
  });

  return filteredEvents
};

// For Range-Bar graphs
export const extractChartItemsWithMatchingEventDatesForRangeBarGraphs = (
  events,
  chartData
) => {
  const formattedEventDates = new Set(
    events?.map((event) => getYYYYMMDDFormat(getCorrectDateTimeString(event.evDate, event.evTime)))
  ); // Create a Set of formatted event dates for efficient lookups.
  // Filter the chartData, if it exists, to keep only items with matching dates.

  const filteredEvents = chartData?.map((chartItem) =>
    formattedEventDates.has(
      getYYYYMMDDFormat(chartItem?.x + " " + chartItem?.z, false)
    ) // combining the date ('16 Jan') with year ('2024')
      ? chartItem
      : { x: chartItem.x, y: null }
  );

  return filteredEvents
};

// For Weight graph
// const extractChartItemsWithMatchingEventDatesForWeightGraph = (
//   events,
//   chartData
// ) => {
//   // Turing the events into 'W 51 2023' format (as the data is coming in 'W 51' format)
//   const formattedEventDates = new Set(
//     events?.map(
//       (event) =>
//         `W ${moment(event.evDate).isoWeek()} ${new Date(
//           event.evDate
//         ).getFullYear()}`
//     )
//   );

//   // Filter the chartData, if it exists, to keep only items with matching dates.
//   return chartData?.map((chartItem) =>
//     formattedEventDates.has(chartItem?.x + " " + chartItem?.z) // combining the week number in 'x' (W 51) with year in 'z' (2023) to make 'W 51 2023' format
//       ? chartItem
//       : { x: chartItem.x, y: null }
//   );
// };

// for the payload of manually add vital
// Return timestamp format "2024-01-16T00:59:08+00:00" instead of '2024-01-16T10:19:51.887Z'
// Return timezone offset in seconds  "2024-01-16T00:59:08+00:00"
export const generateTimestampAndOffset = (dateStr, timeStr) => {
  // Combine date and time strings and create a Date object
  const dateTimeStr = `${dateStr}T${timeStr}:00`;
  const dateTime = new Date(dateTimeStr);

  const timestamp = dateTime.toISOString().replace(/\.\d{3}Z$/, "+00:00"); // Get the timestamp in the required format

  const timezoneOffsetSeconds = dateTime.getTimezoneOffset() * 60; // Get the timezone offset in seconds

  // Issue: 0001 - Please reconsider the timestamp sign
  const timezoneOffsetSign = timezoneOffsetSeconds >= 0 ? "-" : "+"; // Determine the sign of the timezone offset

  const timezoneOffset = `${timezoneOffsetSign}${Math.abs(
    timezoneOffsetSeconds
  )}`; // Format the timezone offset

  return [timestamp, timezoneOffset];
};

export const roundToOneDecimal = (value) => Math.trunc(value * 10) / 10;

export const formatTimeIn12HourFormat = (evTime) =>
  moment(evTime, ["hh:mm A"]).format("hh:mm A");

// format date from "2024-02-01T09:49:00" into "Feb 1, 2024"
export const formatDate = (dateString, localized = true) => {
  const newDateString = localized ? dateString + "Z" : dateString
  const formattedDate = moment(newDateString).format("MMM DD, YYYY");
  return formattedDate
}
// new Date(inputDate + "Z").toLocaleDateString("en-US", {
//   year: "numeric",
//   month: "short",
//   day: "numeric",
// });

// Formats a date string from "YYYY-MM-DDTHH:MM:SS" into "Mon 1"
// Example: convertDateToShortFormat("2024-02-01T09:49:00") returns "Feb 1"
export const convertDateToShortFormat = (inputDate) =>
  new Date(inputDate)
    .toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    })
    .split(",")
    .at(0);

// format date from "2024-02-01T09:00:00" into "09:00 PM"
// also turning it into localTimeZone
export const formatTime = (dateString) => {
  return moment(dateString + "Z").format("hh:mm A");
}
// new Date(inputDate + "Z") // adding Z as timezone is not coming from BE; but time is UTC
//   .toLocaleDateString("en-US", {
//     hour: "numeric",
//     minute: "numeric",
//     hour12: true,
//   })
//   .split(",")
//   .at(1);

// "15:38:00" -> "09:00 PM"
export const convertTo24HourFormat = (time) => {
  // Assuming that time is in the format "15:38:00"
  const momentTime = moment(time, "hh:mm:ss");

  // Get the user's timezone offset
  const timezoneOffset = momentTime.utcOffset();

  // Convert the time to the user's local time
  const localTime = momentTime.utc().utcOffset(timezoneOffset);

  // Format the local time as "HH:mm A"
  const formattedTime = localTime.format("hh:mm A");

  return formattedTime;
};

// getting used in CalendarSchedule
export const formattedTimeStamp = (time) => {
  const currentTime = moment();
  const parsedTime = moment.utc(time); // Parse time as UTC

  const userTimezone = moment.tz.guess(); // Get user's timezone

  // Convert the parsed time to the user's timezone
  parsedTime.tz(userTimezone);

  if (parsedTime.isSame(currentTime, "day")) {
    // If the timestamp is from today, display as "Now"
    return parsedTime.format("MMMM D, h:mmA");
  } else if (parsedTime.isSame(currentTime, "year")) {
    // If the timestamp is from this year, display the time
    return parsedTime.format("MMMM D, h:mmA");
  } else {
    // If the timestamp is from a different year, display the full date and time
    return parsedTime.format("MMMM D, YYYY, h:mmA");
  }
};

// Generates a timestamp string in the format "MonthName day, time"
// Example: generateFormattedTimeStamp("2024-02-01T09:49:00") returns "February 1, 9:49 AM"
export const generateFormattedTimeStamp = (inputDate = new Date()) =>
  new Date(inputDate)
    .toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    })
    .split(",")
    .at(0) +
  ", " +
  formatTime(inputDate);

// 2024-01-12T23:00:00 => YYYY-MM-DD
// UTC => Local
export const getDateFromDate = (dateString) => {
  return moment(dateString + "Z").format("YYYY-MM-DD");
};

// 2024-01-12T23:00:00 => YYYY-MM-DDTHH:mm:ss
// UTC => Local
export const getDateTimeFromDate = (dateString) => {
  return moment(dateString + "Z").format("YYYY-MM-DDTHH:mm:ss");
};

// 2024-01-12T23:00:00 => 13
// UTC => Local
export const getDayFromDate = (dateString) => {
  return moment(dateString + "Z").format("DD");
};

export const getWholeDateFromUTC = (dateString) => {
  return moment(dateString + "Z").format("YYYY-MM-DD");
};

export const getWholeTimeFromUTC = (dateString) => {
  return moment(dateString + "Z").format("HH:mm");
};

// 2024-01-12T20:00:00 => May
// UTC => Local
export const getMonthFromDate = (dateString) => {
  return moment(dateString + "Z").format("MMM");
};

// 2024-01-12T20:00:00 => 01:30 AM
// UTC => Local
export const getTimeFromDate = (dateString) => {
  return moment(dateString + "Z").format("hh:mm A");
};

// 2024-01-12T20:00:00 => 20:00
// UTC => Local
export const getMilitaryTimeFromDate = (dateString) => {
  return moment(dateString + "Z").format("HH:mm");
};

// 2024-01-12T00:00:00, 18:30:24 => 2024-01-12T18:30:24
export const getCorrectDateTimeString = (date, time) =>
  date.split("T").at(0) + "T" + time;

// Extracts the day of the month from a given date
// Example: getDate("2024-03-25") returns 25
export const getDayOfMonth = (date) => {
  // Parse the date in UTC format
  const utcDate = new Date(date);

  // Create a new Date object in the local time zone
  const localDate = new Date(
    utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
  );

  // Get the day of the month from the local date
  return localDate.getDate();
};

// Retrieves the short name of the month from a given date
// Example: getShortMonth("2024-03-25") returns "Mar"
export const getShortMonthName = (date) =>
  new Date(date).toLocaleString("default", { month: "short" });

// this needs attention!!! see if this can be improved or there is a similar function here? or name can be improved
export const formattedTime = (time) => {
  const currentTime = moment();

  const parsedTime = moment.utc(time); // Parse time as UTC

  const userTimezone = moment.tz.guess(); // Get user's timezone

  // Convert the parsed time to the user's timezone
  parsedTime.tz(userTimezone);

  if (parsedTime.isSame(currentTime, "day")) {
    // If the timestamp is from today, display as "Now"
    return parsedTime.format("MMMM D, h:mmA");
  } else if (parsedTime.isSame(currentTime, "year")) {
    // If the timestamp is from this year, display the time
    return parsedTime.format("MMMM D, h:mmA");
  } else {
    // If the timestamp is from a different year, display the full date and time
    return parsedTime.format("MMMM D, YYYY, h:mmA");
  }
};

// YYYY-MM-DDTHH:mm:ss => bool
export const isFutureDateTime = (dateTimeString) => {
  // Construct the event datetime using moment
  const eventDateTimeUTC = moment.utc(
    dateTimeString,
    "YYYY-MM-DDTHH:mm:ss"
  );

  // Convert the event datetime to local time
  const eventDateTimeLocal = eventDateTimeUTC.local();

  // Get the current local datetime using moment
  const currentDateTime = moment();

  // Compare event datetime with the current datetime
  return eventDateTimeLocal.isBefore(currentDateTime);
};